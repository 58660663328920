import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"

import styles from "./hero.module.css"

const Hero = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "group-of-people-seated-around-a-table-having-a-discussion.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroCloudImage1: file(relativePath: { eq: "cloud1.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroCloudImage2: file(relativePath: { eq: "cloud2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const titles = title.split(" ")
  const { scrollYProgress } = useViewportScroll()
  const y = useTransform(scrollYProgress, [0, 1], [-100, 200])

  return (
    <BackgroundImage
      Tag="section"
      className={styles.hero}
      fluid={data.heroImage.childImageSharp.fluid}
      loading="eager"
      backgroundColor={`#040e18`}
    >
      <div className={styles.cloudImageWrapper}>
        <div className={styles.cloudImage1}>
          <Img
            fluid={data.heroCloudImage1.childImageSharp.fluid}
            fadeIn={false}
            loading="eager"
            className={[styles.img, styles.floating].join(" ")}
            style={{ overflow: "visible" }}
          />
        </div>
        <div className={styles.cloudImage2}>
          <Img
            fluid={data.heroCloudImage2.childImageSharp.fluid}
            fadeIn={false}
            loading="eager"
            className={styles.img}
          />
        </div>
      </div>
      <motion.div className={styles.heroTextWrapper} style={{ y }}>
        <h1 className={styles.title}>
          {titles.map((t, i) => {
            return (
              <span key={i}>
                <span className={styles.textBlue}>{t[0]}</span>
                {t.substring(1, t.length)}{" "}
              </span>
            )
          })}
        </h1>
        <p className={styles.description}>
          A weekly quiz about digital transformation in business
        </p>
      </motion.div>
    </BackgroundImage>
  )
}

export default Hero
